<template>
    <div class="bind_login_container body_white">
        <div class="bind_title">
            绑定工号
        </div>

        <div class="account_info">

            <van-field v-model="account" error required label="用户账号" placeholder="请输入账号" />

            <!-- 输入密码 -->
            <van-field v-model="password" error required type="password" label="用户密码" placeholder="请输入密码" />


            <div class="ctrl_bottom" v-if="show">
                <button @click="bindLoginCtrl" class="submit_btn">提交</button>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import { bindaccount } from '@/api/login'
    import { getOpenid } from '@/utils/auth'
    import {Toast} from "vant";
    import {initWxJssdkFilter} from "../../utils/common";
    export default {
        data() {
            return{
                account: '',
                password: '',
                isUserBind : '0',
                show: true
            }
        },
        computed: {
             ...mapGetters([
                'isbind',
            ]),
        },
        mounted() {
            this.$store.dispatch('hideOrShowNav', false)
        },
        methods: {
            bindLoginCtrl(){
                if(this.account == null || this.account == '') {
                    Toast.fail('请输入账号');
                    return
                }
                if(this.password == null || this.password == '') {
                    Toast.fail('请输入密码');
                    return
                }
                let data = {
                    openid: getOpenid(),
                    account: this.account,
                    password: this.password
                }
                bindaccount(data).then(() => {
                    Toast.success('绑定成功，请重新打开页面');
                    console.log(this.isbind,'前')
                    this.isUserBind = '1'
                    this.$store.dispatch('changeBind', this.isUserBind)
                    let second = 2;
                    // var _this = this
                    const timer = setInterval(() => {
                        second--;
                        if (second === 0) {
                            clearInterval(timer);
                            initWxJssdkFilter();
                            // _this.$router.replace('/')
                        }
                    }, 1000);

                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/flex.scss';
    .bind_login_container{
        padding: 30px 20px;
    }
    .bind_title{
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
        font-weight: 600;
        color:#222;
    }

    .ctrl_bottom {
        width: 100%;
        height: 54px;
        background: #fff;
        z-index: 200;
        padding: 16px;
    }
    .ctrl_bottom  .submit_btn {
        background-image: linear-gradient(270deg, #F3BB5A 22%, #E69F11 100%);
        border-radius: 10px;
        margin: 0 auto;
        width: 100%;
        height: 54px;
    }
</style>